@import "./main.scss";

.no_match {
	@extend %flex-center-ver;
	margin: 0 auto;

	&__img {
		margin-top: 6.5rem;
	}

	&__heading {
		@extend %fz-3;
		margin-top: 2rem;
		font-weight: 600;
		color: $color-grey-1;
	}

	&__text {
		margin-top: 1rem;
		color: $color-grey-1;
	}
}

.footer {
	width: 100%;
	height: 5.125rem;
	padding: 1rem 0 2rem 0;

	@extend %flex-center-ver;

	color: $color-grey-3;

	&__highlight {
		font-weight: 600;
	}
}

.cancel {
	width: 16rem;
	height: 9.5rem;
	background-color: $color-white;
	border-radius: $br-sm;
	overflow: hidden;
	@extend %flex-center-ver;

	&__content {
		padding: 1.5rem 0;
		@extend %flex-center-ver;
		color: $color-grey-1;
		width: 100%;
		background-color: $color-grey-5;
	}

	&__heading {
		@extend %fz-3;
		margin-bottom: 0.5rem;
		line-height: 1.75rem;
	}

	&__btn {
		flex-grow: 1;
		flex-basis: 0;
		display: grid;
		grid-template-columns: 1fr 1fr;
		width: 100%;
		color: $color-grey-2;
	}
}
