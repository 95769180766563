%fz-1 {
	font-size: $fz-1;

	@include respond(large) {
		font-size: $fz-1-lg;
	}
}

%fz-2 {
	font-size: $fz-2;

	@include respond(large) {
		font-size: $fz-2-lg;
	}
}

%fz-3 {
	font-size: $fz-3;

	@include respond(large) {
		font-size: $fz-3-lg;
	}
}

%fz-4 {
	font-size: $fz-4;

	@include respond(large) {
		font-size: $fz-4-lg;
	}
}

%fz-5 {
	font-size: $fz-5;

	@include respond(large) {
		font-size: $fz-5-lg;
	}
}

%fz-6 {
	font-size: $fz-6;

	@include respond(large) {
		font-size: $fz-6-lg;
	}
}

%flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

%flex-center-ver {
	display: flex !important;
	flex-direction: column;
	align-items: center;
}

%flex-center-hor {
	display: flex;
	align-items: center;
}

%card_hover {
	transform: scale(1);
	transition: all 0.5s !important;

	&:hover {
		box-shadow: 0 4px 10px 0 rgba(67, 74, 84, 0.14);
		cursor: pointer;
		transform: scale(1.05);
	}
}
