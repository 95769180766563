@import "./main.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  outline: none;
  border: none;
  background-color: transparent;
  list-style: none;
  text-decoration: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

html {
  font-size: 16px;
  overflow: hidden;
}

html:lang(zh-TW) {
  font-family: "PingFang TC", "Noto Sans TC", "Microsoft JhengHei", serif;
}

html:lang(en) {
  font-family: "SF pro", "Roboto", Arial, Helvetica, serif;
}

body {
  width: 100vw;
  height: var(--vh);
  overflow: scroll;
}

#root {
  width: 100vw;
  min-height: var(--vh);
  background-color: $color-grey-5;
  position: relative;

  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
  flex-basis: 0;
}

.main {
  @extend %flex-center-ver;
}

button {
  cursor: pointer;
}

#modal {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.64);
  z-index: 99999;

  @extend %flex-center;
}

img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
