$color-primary-dark: #56667d;
$color-primary-gold: #d4a645;
$color-aqua: #4fc1e9;
$color-blue: #5d9beb;
$color-green: #48cfad;
$color-red: #fc6e51;
$color-yellow: #ffce53;
$color-grey-1: #434a54;
$color-grey-2: #656d78;
$color-grey-3: #aab2bd;
$color-grey-4: #e5e9ed;
$color-grey-5: #f6f6f6;
$color-white: #ffffff;

$br-xs: 0.25rem; // 4px
$br-sm: 0.5rem; // 8px
$br-md: 1rem; // 16px
$br-lg: 16rem; // 96px
$br-cl: 50%;

$spacing-1: 0.25rem; // 4px
$spacing-2: 0.5rem; // 8px
$spacing-3: 0.875rem; // 14px
$spacing-4: 1rem; // 16px
$spacing-5: 1.25rem; // 20px
$spacing-6: 2rem; // 32px
$spacing-7: 4rem; // 64px

$fz-heading-1: 1.75rem; // 28px
$fz-heading-2: 1.5rem; // 24px
$fz-btn: 1rem; // 16px
$fz-body: 0.875rem; // 14px
$fz-tiny: 0.75rem; // 12px

$fz-1: 1.75rem; // 28px
$fz-2: 1.5rem; // 24px
$fz-3: 1.25rem; // 20px
$fz-4: 1rem; // 16px
$fz-5: 0.875rem; // 14px
$fz-6: 0.75rem; // 12px

$fz-1-lg: 2rem; // 32px
$fz-2-lg: 1.75rem; // 28px
$fz-3-lg: 1.375rem; // 22px
$fz-4-lg: 1.125rem; // 18px
$fz-5-lg: 1rem; // 16px
$fz-6-lg: 0.875rem; // 14px

$zh-ff: "PingFang TC", "Noto Sans TC", "Microsoft JhengHei";
$eg-ff: "SF pro", Roboto, Arial, Helvetica;
