@import "./main.scss";

.banner {
  position: relative;
  width: 100%;
  height: 11.875rem;
  z-index: 1;
  background-color: $color-grey-4;
  box-shadow: inset 0 -2.5625rem 50px 0 rgba(0, 0, 0, 0.18);

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @include respond(large) {
    height: 17rem;
  }
}

.content {
  position: relative;
  margin-top: -2.125rem !important;
  background-color: $color-grey-5;
  border-top-left-radius: $br-sm;
  border-top-right-radius: $br-sm;
  overflow: visible;
  z-index: 2;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  &__logo {
    margin-top: -1.875rem;
    margin-bottom: 0.75rem;
    border-radius: 50%;
    height: 6.75rem;
    width: 6.75rem;
    overflow: hidden;

    border: 4px solid white;
    @extend %flex-center;
    background-color: $color-grey-4;

    @include respond(large) {
      margin-top: -2rem;
    }

    &--icon {
      font-size: 3rem;
      color: $color-white;
    }
  }

  &__heading {
    font-weight: 600;
    color: $color-grey-1;
    margin-bottom: 1.5rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    &--title {
      line-height: 2.125rem;
      text-align: center;

      &__sub {
        line-height: 1.75rem;
        @extend %fz-3;
      }
    }
  }

  &__greeting {
    line-height: 1.375rem;
    text-align: center;
    color: $color-grey-2;

    margin-bottom: 1rem;

    @include respond(large) {
      line-height: 1.63;
    }
  }

  &__block {
    width: 100%;
    padding: 1rem;
    background-color: white;
    border-radius: 4px;

    margin-bottom: 1rem;
  }

  &__infor {
    &--heading {
      width: 100%;
      line-height: 1.375rem;
      @extend %fz-4;
      font-weight: 600;
      color: $color-grey-1;
      margin-bottom: 1rem;

      display: flex;
      align-items: center;
      justify-content: space-between;

      @include respond(large) {
        line-height: 1.5625rem;
      }

      &__time {
        color: $color-primary-gold;
        width: fit-content;
      }
    }

    &--list {
      &__questions > * {
        &:not(:last-child) {
          margin-bottom: 1.0625rem !important;

          @include respond(large) {
            margin-bottom: 1.25rem !important;
          }
        }
      }

      &__order > * {
        &:not(:last-child) {
          margin-bottom: 0.5rem;
        }
      }
    }

    &--item {
      line-height: 1.0625rem;
      @extend %fz-6;

      @include respond(large) {
        line-height: 1.25rem;
      }

      display: flex;
      align-items: flex-start;

      & > * {
        @extend %flex-center-hor;
      }
    }

    &--resource {
      color: $color-grey-2;
      font-weight: 600;
      @extend %fz-5;
    }

    &--label {
      color: $color-grey-2;
      margin-right: 1.25rem;

      min-width: max-content;
    }

    &--infor {
      color: $color-grey-1;

      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &__1 {
        @extend %flex-center-ver;
      }
    }

    &--questions {
      display: flex;
      flex-direction: column;
    }

    &--question {
      margin-bottom: 0.5rem;
    }

    &--answer {
      margin-bottom: 1rem;
      color: $color-primary-gold;

      @include respond(large) {
        margin-bottom: 1.5rem;
      }
    }

    &--service {
      // &:not(:last-child):after {
      // 	font-family: "Noto Sans TC";
      // 	content: "、";
      // }
    }

    &--subService {
      color: $color-primary-gold;
    }
  }

  &__social {
    &--list {
      width: 100%;

      &__1 {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }

      &__2 {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        padding-left: 2.6875rem;
        padding-right: 2.625rem;
      }
    }

    &--item {
      padding: 1rem;
      cursor: pointer;
      user-select: none;
      height: 5rem;

      & a {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &.disable > *,
      &.disable {
        cursor: default;
        opacity: 0.3;
      }

      &.disable:hover {
        opacity: 0.3;
      }

      &:hover {
        opacity: 0.8;
      }
    }

    &--text {
      line-height: 1.0625rem;
      font-size: 0.6875rem;
      color: $color-grey-2;
    }

    &--icon {
      height: 2rem;
      width: 2rem;
      @extend %flex-center;

      &__map * {
        fill: $color-aqua;
      }
    }
  }

  &__btn {
    display: grid;
    width: 100%;
    margin-bottom: 1rem;

    @include respond(large) {
      margin-bottom: 2rem;
    }

    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    column-gap: 1rem;

    // &--1 {
    // grid-template-columns: repeat(2, 1fr);
    // column-gap: 1rem;
    // grid-template-columns: 1fr;
    // }

    // &--2 {
    // grid-template-columns: 1fr;
    // }

    &--btn {
      border-radius: 2rem;
      height: 2.625rem;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      &__cancel {
        color: white;
        background-color: $color-primary-dark;
      }
    }
  }
}

.dial {
  width: 16rem;
  border-radius: $br-sm;
  background-color: $color-grey-5;
  position: relative;
  overflow: hidden;

  @extend %flex-center-ver;
  padding-top: 3.5rem;

  @include respond(large) {
    width: 20rem;
  }

  &__text {
    width: 9.625rem;
    @extend %fz-5;
    text-align: center;
    margin-bottom: 1rem;
    color: $color-grey-1;

    @include respond(large) {
      width: 11rem;
    }
  }

  &__content {
    @extend %fz-3;
    text-align: center;
    color: $color-grey-1;
    margin-bottom: 1.5rem;

    &--text {
      margin-bottom: 3px;
    }

    &--phone {
      @extend %fz-2;
      color: $color-primary-gold;
    }
  }

  &__dialing {
    display: block;
    padding: 0.8125rem;
    text-align: center;
    font-weight: 600;
    color: $color-grey-1;
    width: 100%;
    background-color: $color-white;
    cursor: pointer;
  }
}
