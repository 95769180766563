/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

img {
	border-style: none;
}

button:focus {
	outline: none;
}
