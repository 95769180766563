@import "./main.scss";

.loader {
	animation: infiniteRotate 2s infinite ease-in-out;
}

.container {
	min-width: 20rem;
	max-width: 22rem;
	width: 100%;
	padding-left: 2rem;
	padding-right: 2rem;
	@extend %flex-center-ver;
}

.heading {
	&__1 {
		font-size: $fz-heading-1;

		@include respond(large) {
			font-size: 2rem;
		}
	}

	&__2 {
		font-size: $fz-heading-2;

		@include respond(large) {
			font-size: 1.75rem;
		}
	}
}

.button {
	font-size: $fz-btn;
	@extend %flex-center;

	@include respond(large) {
		font-size: 1rem;
	}

	&:focus,
	&:hover {
		opacity: 0.8;
		cursor: pointer;
	}

	&:disabled {
		opacity: 0.3;
		cursor: unset;
	}
}

.body {
	font-size: $fz-body;

	@include respond(large) {
		font-size: 1rem;
	}
}

.tiny {
	font-size: $fz-tiny;

	@include respond(large) {
		font-size: 0.75rem;
	}
}

.btn {
	&__primary {
		color: $color-white;
		background-color: $color-primary-dark;
		border-radius: $br-lg;
	}

	&__logout,
	&__notification {
		width: 2.75rem;
		height: 2.75rem;
		cursor: pointer;

		position: fixed;
		top: 1rem;

		@extend %flex-center;
	}

	&__notification {
		right: 1rem;
		font-size: 1.25rem;
		color: $color-primary-dark;

		@include respond(large) {
			right: 2rem;
		}

		&--remind {
			width: 0.375rem;
			height: 0.375rem;
			border-radius: $br-cl;
			background-color: $color-red;

			position: absolute;
			top: 0.625rem;
			right: 0.5rem;
		}
	}

	&__logout {
		left: 1rem;
		font-size: 1.5rem;
		color: $color-grey-3;

		@include respond(large) {
			left: 2rem;
		}

		& > * {
			transform: scale(-1, 1);
		}
	}

	&__close {
		width: 2.75rem;
		height: 2.75rem;

		@extend %flex-center;

		position: absolute;
		top: 1rem;
		right: 1rem;
		font-size: 1.5rem;
		color: $color-primary-dark;
		cursor: pointer;

		@include respond(large) {
			right: 2rem;
		}

		&--voice_valid {
			top: 0.5rem;
			right: 0.5rem;

			& > * {
				width: 1rem;
				height: 1rem;
			}
		}

		&--back {
			left: 1rem;

			@include respond(large) {
				left: 2rem;
			}
		}
	}
}

.label {
	&__status {
		width: 100%;
		line-height: 1.0625rem;
		margin-bottom: 0.5rem;
		padding: 0.125rem 0;
		text-align: center;
		border-radius: $br-xs;
		color: $color-white;

		&--no_show {
			background-color: $color-red;
		}

		&---unpaid_cancel,
		&--consumer_cancel,
		&--cancel {
			background-color: $color-grey-3;
		}

		&--checked_in {
			background-color: $color-green;
		}
	}
}
